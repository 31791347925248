import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCategories } from "../api";

const initialState = {
  fetching: false,
  fetched: false,
  categories: [
    {
      id: 1,
      urlNameFr: "fiscalite",
      urlNameNl: "fiscaliteit",
      urlNameEn: "tax",
      nameFr: "Fiscalité",
      nameNl: "Fiscaliteit",
      nameEn: "Tax",
    },
    {
      id: 2,
      urlNameFr: "profession",
      urlNameNl: "beroep",
      urlNameEn: "profession",
      nameFr: "Profession",
      nameNl: "Beroep",
      nameEn: "Profession",
    },
    {
      id: 3,
      urlNameFr: "grh-emploi-formation",
      urlNameNl: "hrm-jobs-training",
      urlNameEn: "hrm-jobs-training",
      nameFr: "GRH, Emploi, formation",
      nameNl: "HRM, jobs & training",
      nameEn: "HRM, jobs & training",
    },
    {
      id: 4,
      urlNameFr: "banque-assurance-finance",
      urlNameNl: "financien",
      urlNameEn: "bank-insurance-finances",
      nameFr: "Banque, Assurance & Finance",
      nameNl: "Financiën",
      nameEn: "Bank, Insurance & Finances",
    },
    {
      id: 5,
      urlNameFr: "apps-cloud-digital",
      urlNameNl: "apps-cloud-digital",
      urlNameEn: "apps-cloud-digital",
      nameFr: "Apps, Cloud & Digital",
      nameNl: "Apps, Cloud & Digital",
      nameEn: "Apps, Cloud & Digital",
    },
    {
      id: 6,
      urlNameFr: "politique-economie",
      urlNameNl: "politiek-economie",
      urlNameEn: "politics-economy",
      nameFr: "Politique et économie",
      nameNl: "Politiek en Economie",
      nameEn: "Politics & Economy",
    },
    {
      id: 7,
      urlNameFr: "patrimoine-finance-personnel",
      urlNameNl: "patrimonium",
      urlNameEn: "patrimony-personal-finance",
      nameFr: "Patrimoine et finance personnel",
      nameNl: "Patrimonium",
      nameEn: "Patrimony & personal finance",
    },
    {
      id: 8,
      urlNameFr: "business",
      urlNameNl: "business",
      urlNameEn: "business",
      nameFr: "Business",
      nameNl: "Business",
      nameEn: "Business",
    },
    {
      id: 9,
      urlNameFr: "social",
      urlNameNl: "social",
      urlNameEn: "social",
      nameFr: "Social",
      nameNl: "Social",
      nameEn: "Social",
    },
    {
      id: 10,
      urlNameFr: "sciences-technologie",
      urlNameNl: "wetenschap-technologie",
      urlNameEn: "science-technology",
      nameFr: "Sciences et Technologie",
      nameNl: "Wetenschap & Technologie",
      nameEn: "Science and technology",
    },
    {
      id: 11,
      urlNameFr: "comptabilite",
      urlNameNl: "accountancy",
      urlNameEn: "accountancy",
      nameFr: "Comptabilité",
      nameNl: "Accountancy",
      nameEn: "Accountancy",
    },
    {
      id: 12,
      urlNameFr: "strategie-marketing-communication",
      urlNameNl: "strategie-marketing-communication",
      urlNameEn: "strategy-marketing-communication",
      nameFr: "Stratégie, Marketing & Communication",
      nameNl: "Strategie, Marketing & Communicatie",
      nameEn: "Strategy, Marketing & Communication",
    },
    {
      id: 13,
      urlNameFr: "droit",
      urlNameNl: "recht",
      urlNameEn: "business-law",
      nameFr: "Droit",
      nameNl: "Recht",
      nameEn: "Business & Law",
    },
    {
      id: 14,
      urlNameFr: "vie-loisirs-culture",
      urlNameNl: "leven-recreatie-cultuur",
      urlNameEn: "life-entertainment-culture",
      nameFr: "Vie, loisirs et culture",
      nameNl: "leven, recreatie & cultuur",
      nameEn: "Life, entertainment & culture",
    },
    {
      id: 15,
      urlNameFr: "management-organisation",
      urlNameNl: "management-organisatie",
      urlNameEn: "management-organisation",
      nameFr: "Management & Organisation",
      nameNl: "Management & Organisatie",
      nameEn: "Management & Organisation",
    },
    {
      id: 16,
      urlNameFr: "audit",
      urlNameNl: "audit",
      urlNameEn: "audit",
      nameFr: "Audit",
      nameNl: "Audit",
      nameEn: "Audit",
    },
    {
      id: 17,
      urlNameFr: "consulting-services",
      urlNameNl: "consulting-services",
      urlNameEn: "consulting-services",
      nameFr: "Consulting & Services",
      nameNl: "Consulting & Services",
      nameEn: "Consulting & Services",
    },
    {
      id: 18,
      urlNameFr: "people",
      urlNameNl: "people",
      urlNameEn: "people",
      nameFr: "People",
      nameNl: "People",
      nameEn: "People",
    },
    {
      id: 19,
      urlNameFr: "environnement-mobilite",
      urlNameNl: "milieu-mobiliteit",
      urlNameEn: "environment-mobility",
      nameFr: "Environnement et Mobilité",
      nameNl: "Milieu en Mobiliteit",
      nameEn: "Environment & Mobility",
    },
    {
      id: 24,
      urlNameFr: "standards-and-ethics",
      urlNameNl: "standards-and-ethics",
      urlNameEn: "standards-and-ethics",
      nameFr: "Normes et déontologie",
      nameNl: "Standaarden en Ethiek",
      nameEn: "Standards and Ethics",
    },
    {
      id: 25,
      urlNameFr: "wellness-sport-and-health",
      urlNameNl: "wellness-sport-and-health",
      urlNameEn: "wellness-sport-and-health",
      nameFr: "Bien-être, sport et santé",
      nameNl: "Wellness, sport en gezondheid",
      nameEn: "Wellness, sport & Health",
    },
    {
      id: 27,
      urlNameFr: "ethique-deontologie",
      urlNameNl: "ethiek-deontologie",
      urlNameEn: "ethics",
      nameFr: "Ethique & Déontologie",
      nameNl: "Ethiek en deontologie",
      nameEn: "Ethics",
    },
  ],
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (tmp, { getState, dispatch }) => {
    const { token } = getState().auth;
    if (!token) {
      return false;
    }

    const response = await getCategories({
      token,
    });

    return response.data.data;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.fetched = true;
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.fetched = true;
    });
  },
});

export default categoriesSlice.reducer;
