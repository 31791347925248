import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import Select from "react-select";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import {
  AGREATION_OPTIONS,
  LEGAL_FORM,
  LANGUAGES,
  SELECT_STYLES,
  COUNTRIES,
} from "../../config";
import SECTORS from "../../config/sectors";
import _ from "../../i18n";
import { updateFolder, saveCommunityPortalsSettings } from "../../api";
import { validateUrl } from "../../utils";

import AgreationNumber from "../common/AgreationNumber";
import FormInput from "../common/FormInput";
import FormSelect from "../common/FormSelect";
import { IconPen } from "../common/icons/IconPen";
import AvatarModal from "../common/AvatarModal";
import styles from "./Settings.module.scss";
import UpgradeModal from "./UpgradeModal";
import WatchSharing from "./tabs/WatchSharing";
import { Link } from "react-router-dom";

const LeftBanner = () => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showWatchModal, setShowWatchModal] = useState(false);
  const fileInput = useRef(null);

  const getFiduciarySize = () => {
    const size = fiduciary.officeSize.split("-");
    if (size.length === 2) {
      return `${_(size[0])}+${_(size[1])} ${_("collab")}.`;
    } else {
      return `${_("more_than")} ${_(size[0])} ${_("collab")}.`;
    }
  };
  const [editingValue, setEditingValue] = useState(null);
  const [saving, setSaving] = useState(false);
  const [isModalAvatarOpened, setIsModalAvatarOpened] = useState(false);

  const [data, setData] = useState({
    image: null,
    name: "",
    agreation: null,
    numeroAgreation: "",
    abbreviation: "",
    url: "",
    address: "",
    language: null,
    legalForm: null,
    sector: null,
    studentDomainEmails: [],
  });

  const [country, setCountry] = useState({ value: "BE", label: COUNTRIES.BE });
  const [portals, setPortals] = useState(null);
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    agreation: AGREATION_OPTIONS[0],
    numeroAgreation: "",
    abbreviation: "",
    url: "",
    address: "",
    zipCode: "",
    city: "",
    language: LANGUAGES[0],
    legalForm: {
      value: "SRL",
      label: LEGAL_FORM.SRL,
    },
    sector: {
      value: SECTORS[0].id,
      label: SECTORS[0].title,
    },
    studentDomainEmails: [],
  });

  const [errors, setErrors] = useState({
    address: "",
    url: "",
  });
  const endEdit = () => {
    setEditingValue(null);
    setFormData({
      image: null,
      name: "",
      agreation: AGREATION_OPTIONS[0],
      numeroAgreation: "",
      abbreviation: "",
      url: "",
      address: "",
      zipCode: "",
      city: "",
      language: LANGUAGES[0],
      legalForm: {
        value: "SRL",
        label: LEGAL_FORM.SRL,
      },
      sector: {
        value: SECTORS[0].id,
        label: SECTORS[0].title,
      },
      studentDomainEmails: [],
    });
    setErrors({
      address: "",
      url: "",
    });
  };
  const updateData = (values) => {
    let languagePreferences = [];
    if (values.languagePreferences) {
      const lngPref = Array.isArray(values.languagePreferences)
        ? values.languagePreferences
        : values.languagePreferences.split(",");
      if (lngPref.length > 0 && lngPref[0] !== "") {
        languagePreferences = lngPref.map((lng) => {
          return { label: _(lng), value: lng };
        });
      }
    }
    setData({
      image: values.avatarUrl ? values.avatarUrl : null,
      name: values.name,
      url: values.website ? values.website : "",
      address: values.address1 ? values.address1 : "",
      zipCode: values.zipCode ? values.zipCode : "",
      city: values.city ? values.city : "",
      numeroAgreation: values.agreation?.number ? values.agreation.number : "",
      agreation: values.agreation?.type
        ? AGREATION_OPTIONS.filter(
            (item) => item.value === values.agreation.type
          )[0]
        : null,
      language: languagePreferences,
      legalForm: values.legalForm
        ? {
            value: values.legalForm,
            label: LEGAL_FORM[values.legalForm],
          }
        : null,
      abbreviation: values.abbreviation ? values.abbreviation : "",
      sector:
        values.sector.id && values.sector.titleFr
          ? {
              value: values.sector.id,
              label: values.sector.titleFr,
            }
          : null,
      studentDomainEmails: values.studentDomainEmails,
    });
  };

  useEffect(() => {
    if (fiduciary) {
      updateData(fiduciary);

      if (fiduciary.country) {
        setCountry({
          value: fiduciary.country,
          label: COUNTRIES[fiduciary.country],
        });
      }
      if (fiduciary.hubPreferences?.portals) {
        setPortals(fiduciary.hubPreferences.portals);
      }
    }
  }, [fiduciary]);

  const save = async () => {
    let data = null;
    if (
      editingValue === "approval_num" &&
      formData.numeroAgreation.length > 0
    ) {
      data = {
        agreation:
          formData.numeroAgreation && formData.numeroAgreation.length > 0
            ? JSON.stringify({
                type: formData.agreation.value,
                number: formData.numeroAgreation,
              })
            : "",
      };
    } else if (editingValue === "name") {
      data = {
        name: formData.name,
      };
    } else if (editingValue === "abbreviation") {
      data = {
        abbreviation: formData.abbreviation,
      };
    } else if (editingValue === "url_link" && errors.url.length === 0) {
      data = {
        url: formData.url,
      };
    } else if (editingValue === "address" && errors.address.length === 0) {
      data = {
        address: formData.address,
      };
    } else if (editingValue === "zipCode" && errors.zipCode.length === 0) {
      data = {
        zipCode: formData.zipCode,
      };
    } else if (editingValue === "city" && errors.city.length === 0) {
      data = {
        city: formData.city,
      };
    } else if (editingValue === "legal_form") {
      data = {
        legalForm: formData.legalForm.value,
      };
    } else if (editingValue === "activity_area") {
      data = {
        sector: formData.sector.value,
      };
    } else if (editingValue === "language") {
      const languages = formData.language.map((l) => l.value);
      data = {
        language: languages.join(","),
      };
    } else if (editingValue === "avatar") {
      data = {
        avatar: formData.image,
      };
    } else if (editingValue === "studentDomainEmails") {
      let domains = formData.studentDomainEmails.map((i) => i.trim());
      data = {
        studentDomainEmails: domains.join(","),
      };
    }
    if (data) {
      setSaving(true);
      const res = await updateFolder(
        auth.token,
        editingValue,
        data,
        fiduciary.id
      );

      if (fiduciary.uaMode === "UNIVERSITY") {
        updateData({
          ...res.data.data,
          studentDomainEmails: formData.studentDomainEmails.map((i) =>
            i.trim()
          ),
        });
      } else {
        updateData(res.data.data);
      }
      endEdit();

      if (
        (editingValue === "address" || editingValue === "city") &&
        errors.address.length === 0
      ) {
        // let address =
        //   formData.address +
        //   " " +
        //   formData.zipCode +
        //   ", " +
        //   formData.city +
        //   ", ";
        let address = formData.address + ", " + formData.city + ", ";
        address += country ? country.label : "Belgique";
        geocodeByAddress(address)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            if (latLng) {
              try {
                saveCommunityPortalsSettings({
                  token: auth.token,
                  communityId: fiduciary.id,
                  data: {
                    portals: {
                      ...portals,
                      public: {
                        ...portals.public,
                        config: {
                          ...portals.public.config,
                          contact: {
                            ...portals.public.config.contact,
                            latitude: latLng.lat,
                            longitude: latLng.lng,
                          },
                        },
                      },
                    },
                    subdomain: "",
                  },
                });
              } catch (e) {}
            }
            setSaving(false);
          })
          .catch((error) => setSaving(false));
      } else {
        setSaving(false);
      }
    }
  };

  const renderEditForm = () => {
    return (
      <div className={styles.form}>
        {editingValue === "approval_num" ? (
          <div className={styles.marginBottom}>
            <AgreationNumber
              NumeroAgreation={formData.numeroAgreation}
              Agreation={formData.agreation}
              setAgreation={(value) =>
                setFormData({ ...formData, agreation: value })
              }
              setNumeroAgreation={(value) =>
                setFormData({ ...formData, numeroAgreation: value })
              }
              small={true}
            />
          </div>
        ) : editingValue === "name" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="name"
            value={formData.name}
            label={_("company_name")}
            autoComplete="none"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          ></FormInput>
        ) : editingValue === "abbreviation" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="abbreviation"
            value={formData.abbreviation}
            label={_("abbreviation")}
            autoComplete="none"
            onChange={(e) =>
              setFormData({ ...formData, abbreviation: e.target.value })
            }
          ></FormInput>
        ) : editingValue === "url_link" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="url_link"
            value={formData.url}
            label={_("url_link")}
            error={errors.url}
            autoComplete="none"
            onChange={(e) => {
              setFormData({ ...formData, url: e.target.value });
              if (e.target.value.length > 0 && !validateUrl(e.target.value)) {
                setErrors({ ...errors, url: _("invalid_url") });
              } else {
                setErrors({ ...errors, url: "" });
              }
            }}
          ></FormInput>
        ) : editingValue === "address" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="address"
            value={formData.address}
            label={_("address")}
            required={true}
            error={errors.address}
            autoComplete="none"
            onChange={(e) => {
              setFormData({ ...formData, address: e.target.value });
              if (e.target.value.length > 0) {
                setErrors({ ...errors, address: "" });
              } else {
                setErrors({ ...errors, address: _("required_field") });
              }
            }}
          ></FormInput>
        ) : editingValue === "zipCode" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="zipCode"
            value={formData.zipCode}
            label={_("zipCode")}
            required={true}
            error={errors.zipCode}
            autoComplete="none"
            onChange={(e) => {
              setFormData({ ...formData, zipCode: e.target.value });
              if (e.target.value.length > 0) {
                setErrors({ ...errors, zipCode: "" });
              } else {
                setErrors({ ...errors, zipCode: _("required_field") });
              }
            }}
          ></FormInput>
        ) : editingValue === "city" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="city"
            value={formData.city}
            label={_("city")}
            required={true}
            error={errors.city}
            autoComplete="none"
            onChange={(e) => {
              setFormData({ ...formData, city: e.target.value });
              if (e.target.value.length > 0) {
                setErrors({ ...errors, city: "" });
              } else {
                setErrors({ ...errors, city: _("required_field") });
              }
            }}
          ></FormInput>
        ) : editingValue === "legal_form" ? (
          <FormSelect
            name="legal_form"
            value={formData.legalForm}
            label={_("legal_form")}
            required={true}
            handleChange={(option) =>
              setFormData({ ...formData, legalForm: option })
            }
            options={Object.entries(LEGAL_FORM).map(([k, v]) => {
              return { value: k, label: v };
            })}
          />
        ) : editingValue === "activity_area" ? (
          <FormSelect
            name="sector"
            value={formData.sector}
            label={_("activity_area")}
            handleChange={(option) =>
              setFormData({ ...formData, sector: option })
            }
            options={SECTORS.map((sect) => {
              return { value: sect.id, label: sect.title };
            })}
          />
        ) : editingValue === "language" ? (
          <div className="ttp-form-group-h">
            <label className="ttp-label">{_("language")}</label>
            <Select
              styles={SELECT_STYLES}
              options={LANGUAGES}
              isSearchable={false}
              isMulti
              value={formData.language}
              onChange={(e) => setFormData({ ...formData, language: e })}
            />
          </div>
        ) : editingValue === "studentDomainEmails" ? (
          <FormInput
            style={{ backgroundColor: "#fff" }}
            name="studentDomainEmails"
            value={formData.studentDomainEmails.join(", ")}
            label={"Domaines emails privés"}
            info="Ex: monuniversite.com, mondomaine.com"
            error={errors.studentDomainEmails}
            autoComplete="none"
            onChange={(e) => {
              setFormData({
                ...formData,
                studentDomainEmails: e.target.value.split(","),
              });
              // if (e.target.value.length > 0) {
              //   setErrors({ ...errors, studentDomainEmails: "" });
              // }
            }}
          ></FormInput>
        ) : null}
        <div className={styles.buttons}>
          <button onClick={() => endEdit()} className={styles.cancelBtn}>
            {_("cancel")}
          </button>
          <button
            className={saving ? styles.disableBtn : ""}
            onClick={() => save()}
          >
            {saving ? "..." : _("save")}
          </button>
        </div>
      </div>
    );
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData({ ...formData, image: e.target.files[0] });
      setIsModalAvatarOpened(true);
    }
  };
  return (
    <>
      <div className={styles.leftBanner}>
        {fiduciary && (
          <>
            <div className={styles.leftBanner_img}>
              {formData.image || data.image ? (
                <div
                  className={styles.img}
                  style={{
                    backgroundImage: `url(${
                      formData.image
                        ? URL.createObjectURL(formData.image)
                        : data.image
                        ? data.image
                        : null
                    })`,
                  }}
                ></div>
              ) : (
                <div className="icon-picture">
                  <i className="icon-ttp-picture"></i>
                </div>
              )}

              <div
                onClick={() => {
                  fileInput.current.click();
                  setEditingValue("avatar");
                }}
                className={styles.edit}
              >
                <IconPen />
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInput}
                accept="image/*"
                value={""}
                onChange={handleFileChange}
              />
            </div>

            {editingValue === "avatar" && (
              <div className={classNames(styles.buttons, styles.avatarBtns)}>
                <button onClick={() => endEdit()} className={styles.cancelBtn}>
                  {_("cancel")}
                </button>
                <button
                  className={saving ? styles.disableBtn : ""}
                  onClick={() => save()}
                >
                  {saving ? "..." : _("save")}
                </button>
              </div>
            )}
            <h3 className={styles.leftBanner_name}>
              {/* {fiduciary.abbreviation ? fiduciary.abbreviation : fiduciary.name} */}

              {editingValue === "abbreviation" ? (
                renderEditForm()
              ) : (
                <div className={styles.value}>
                  <div className={styles.valueContent}>
                    {data.abbreviation ? data.abbreviation : ""}
                  </div>
                  <div
                    className={styles.edit}
                    onClick={() => {
                      setEditingValue("abbreviation");
                      setFormData({
                        ...formData,
                        abbreviation: data.abbreviation,
                      });
                    }}
                  >
                    <IconPen />
                  </div>
                </div>
              )}
            </h3>
            <ul>
              {fiduciary.uaMode !== "UNIVERSITY" && (
                <>
                  <li>
                    <div className={styles.content_li}>
                      {_("business_num")} : <span>{fiduciary.uen}</span>
                    </div>
                  </li>
                  <li
                    className={
                      editingValue === "approval_num" ? styles.active_li : ""
                    }
                  >
                    <div className={classNames(styles.content_li)}>
                      {editingValue === "approval_num" ? (
                        renderEditForm()
                      ) : (
                        <div className={styles.value}>
                          <div className={styles.valueContent}>
                            {_("approval_num")} : {data.agreation && <br />}
                            <span className={styles.approval_num}>
                              {data.agreation
                                ? data.agreation.label + " : "
                                : ""}
                              {data.numeroAgreation && (
                                <InputMask
                                  readOnly={true}
                                  mask={
                                    data.agreation &&
                                    data.agreation.type === "ITAA"
                                      ? "99 . 999 . 999"
                                      : ""
                                  }
                                  value={data.numeroAgreation}
                                />
                              )}
                            </span>
                          </div>
                          <div
                            className={styles.edit}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                agreation: data.agreation
                                  ? data.agreation
                                  : formData.agreation,
                                numeroAgreation: data.numeroAgreation,
                              });
                              setEditingValue("approval_num");
                            }}
                          >
                            <IconPen />
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                </>
              )}
              <li
                className={
                  editingValue === "abbreviation" ? styles.active_li : ""
                }
              >
                <div className={classNames(styles.content_li)}>
                  {editingValue === "name" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("company_name")} : <span>{data.name}</span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("name");
                          setFormData({
                            ...formData,
                            name: data.name,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={editingValue === "url_link" ? styles.active_li : ""}
              >
                <div className={styles.content_li}>
                  {editingValue === "url_link" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("url_link")} : <span>{data.url}</span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("url_link");
                          setFormData({
                            ...formData,
                            url: data.url,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={editingValue === "address" ? styles.active_li : ""}
              >
                <div className={styles.content_li}>
                  {editingValue === "address" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("address")} : <span>{data.address} </span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("address");
                          setFormData({
                            ...formData,
                            address: data.address,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={editingValue === "zipCode" ? styles.active_li : ""}
              >
                <div className={styles.content_li}>
                  {editingValue === "zipCode" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("zipCode")} : <span>{data.zipCode} </span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("zipCode");
                          setFormData({
                            ...formData,
                            zipCode: data.zipCode,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li className={editingValue === "city" ? styles.active_li : ""}>
                <div className={styles.content_li}>
                  {editingValue === "city" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("city")} : <span>{data.city} </span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("city");
                          setFormData({
                            ...formData,
                            city: data.city,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={
                  editingValue === "legal_form" ? styles.active_li : ""
                }
              >
                <div className={styles.content_li}>
                  {editingValue === "legal_form" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("legal_form")} :{" "}
                        <span>
                          {data.legalForm ? data.legalForm.value : ""}
                        </span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("legal_form");
                          setFormData({
                            ...formData,
                            legalForm: data.legalForm
                              ? data.legalForm
                              : formData.legalForm,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={
                  editingValue === "activity_area" ? styles.active_li : ""
                }
              >
                <div className={styles.content_li}>
                  {editingValue === "activity_area" ? (
                    renderEditForm()
                  ) : (
                    <div
                      className={styles.value}
                      onClick={() => {
                        setEditingValue("activity_area");
                        setFormData({
                          ...formData,
                          sector: data.sector ? data.sector : formData.sector,
                        });
                      }}
                    >
                      <div className={styles.valueContent}>
                        {_("activity_area")} :{" "}
                        <span>{data.sector ? data.sector.label : "-"}</span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("activity_area");
                          setFormData({
                            ...formData,
                            sector: data.sector ? data.sector : formData.sector,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <li
                className={editingValue === "language" ? styles.active_li : ""}
              >
                <div className={classNames(styles.content_li, styles.last)}>
                  {editingValue === "language" ? (
                    renderEditForm()
                  ) : (
                    <div className={styles.value}>
                      <div className={styles.valueContent}>
                        {_("language")} :{" "}
                        <span>
                          {data.language
                            ? data.language.map((i) => i.label + " ")
                            : ""}
                        </span>
                      </div>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setEditingValue("language");
                          setFormData({
                            ...formData,
                            language: data.language
                              ? data.language
                              : formData.language,
                          });
                        }}
                      >
                        <IconPen />
                      </div>
                    </div>
                  )}
                </div>
              </li>

              {fiduciary.uaMode === "UNIVERSITY" && (
                <li
                  className={
                    editingValue === "studentDomainEmails"
                      ? styles.active_li
                      : ""
                  }
                >
                  <div className={classNames(styles.content_li, styles.last)}>
                    {editingValue === "studentDomainEmails" ? (
                      renderEditForm()
                    ) : (
                      <div className={styles.value}>
                        <div className={styles.valueContent}>
                          {"Domaines emails privés"} :{" "}
                          <span>
                            {data.studentDomainEmails
                              ? data.studentDomainEmails.join(", ")
                              : ""}
                          </span>
                        </div>
                        <div
                          className={styles.edit}
                          onClick={() => {
                            setEditingValue("studentDomainEmails");
                            setFormData({
                              ...formData,
                              studentDomainEmails: data.studentDomainEmails
                                ? data.studentDomainEmails
                                : formData.studentDomainEmails,
                            });
                          }}
                        >
                          <IconPen />
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </>
        )}
      </div>
      {fiduciary && !["UNIVERSITY", "COMMUNITY"].includes(fiduciary.uaMode) && (
        <>
          <div className={styles.subscriptionDetails}>
            <h3>Abonnement United Associates</h3>

            <div className={styles.subscriptionDetails_top}>
              <div className={styles.icon}>
                <i className="icon-ttp-check"></i>
              </div>

              <div>
                Vous avez opté pour la formule :{" "}
                {fiduciary && fiduciary.officeSize && (
                  <span>{getFiduciarySize()}</span>
                )}
              </div>
            </div>
            {fiduciary.uaMode === "CLASSIC" && (
              <>
                <p>
                  Si vous souhaitez changer la taille de votre entreprise vous
                  devrez {"  "}
                  <a href="#">contacter le support</a> United Associates.
                </p>

                <p
                  onClick={() => setShowUpgradeModal(true)}
                  className={styles.subscriptionDetails_link}
                >
                  Augmenter la taille de mon entreprise
                </p>
              </>
            )}

            <div className={styles.subscriptionDetails_watch}>
              <h3>Partage Offfcourse Premium</h3>
              <p>
                Vous avez la possibilité de partager votre abonnement premium
                avec vos collaborateurs
              </p>
              <p
                className={styles.subscriptionDetails_link}
                onClick={() => setShowWatchModal(true)}
              >
                Gérer les droits d’utilisateurs
              </p>
            </div>
          </div>
          {fiduciary.subscriptionId && fiduciary.subscription !== "PENDING" && (
            <Link to={"/manage-subscription"} className={styles.manage_account}>
              {_("manage_account")}
            </Link>
          )}
        </>
      )}
      <AvatarModal
        visible={isModalAvatarOpened}
        onClose={() => setIsModalAvatarOpened(false)}
        onCancel={() => setFormData({ ...formData, image: null })}
        avatar={formData.image}
        onSaveAvatar={(blob) => setFormData({ ...formData, image: blob })}
        uaSettings={true}
      />
      <UpgradeModal
        showModal={showUpgradeModal}
        setShowModal={setShowUpgradeModal}
      />
      <WatchSharing
        showModal={showWatchModal}
        setShowModal={setShowWatchModal}
      />
    </>
  );
};

export default LeftBanner;
