import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import SendWidget from "../common/Widgets/SendWidget";
import { handleTalkWidget } from "../common/Widgets/talk";
import Button from "../common/Button";

import { ALLOWED_APPS } from "../../config";
import { store, setCurrentPortal, setHideFooter } from "../../store";
import { getTeamPremium } from "../../api";
import AppsList from "./AppsList";
import Drawer from "./Drawer";
// import ArticleWidget from "./ArticleWidget";
import _ from "../../i18n";
import styles from "./CollaboratorPortal.module.scss";

const CollaboratorPortal = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [collaboratorEnabled, setCollaboratorEnabled] = useState(true);
  const [activeApp, setActiveApp] = useState("");
  const [apps, setApps] = useState([]);
  const [hasPremiumWatch, setHasPremiumWatch] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedIsAdmin } = auth;

  useEffect(() => {
    if (activeApp === "FAQ") {
      dispatch(setHideFooter(true));
    } else {
      dispatch(setHideFooter(false));
    }
  }, [activeApp]);

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search).get("params");
    let isNlRedirection = false;
    if (params) {
      isNlRedirection = true;
    }
    if (fiduciary) {
      if (fiduciary.hubPreferences && fiduciary.hubPreferences.portals) {
        if (fiduciary.hubPreferences.portals.collaborator.enabled) {
          let config = fiduciary.hubPreferences.portals.collaborator.config;
          dispatch(setCurrentPortal("COLLABORATOR"));

          const handleWatch = async () => {
            try {
              const resp = await getTeamPremium({
                token: auth.token,
                ids: [auth.user.id],
              });
              if (
                resp.data.data &&
                resp.data.data.users &&
                resp.data.data.users.length > 0
              ) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              return false;
            }
          };

          // const tab = config
          //   ? config.apps.filter(
          //       (app) => app.enabled && ALLOWED_APPS.includes(app.id)
          //     )
          //   : [];
          let tab = [];
          if (config) {
            let watchApp = null;
            let sortingArray = [];
            config.apps.forEach((app) => {
              if (app.enabled && ALLOWED_APPS.includes(app.id)) {
                sortingArray.push(app.id);
                if (app.id === "OFFFCOURSE") {
                  watchApp = app;
                } else {
                  tab.push(app);
                }
              }
            });
            if (watchApp) {
              try {
                const result = await handleWatch();
                if (result) {
                  setHasPremiumWatch(true);
                  tab.push(watchApp);
                  tab.sort(
                    (a, b) =>
                      sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id)
                  );
                  setApps(tab);
                } else {
                  setApps(tab);
                }
              } catch (e) {
                setApps(tab);
              }
            } else {
              setApps(tab);
            }
          }

          if (tab.length > 0) {
            let queryStringApp = location.search;
            if (location.search.length > 0) {
              queryStringApp = location.search.split("?")[1].includes("&")
                ? location.search.split("?")[1].split("&")[0].split("=")[1]
                : location.search.split("?")[1].split("=")[1];
            }
            if (queryStringApp.length > 0) {
              setActiveApp(queryStringApp);
            } else if (!activeApp) {
              setActiveApp(tab[0].id);
              isNlRedirection && setActiveApp("NEWSLETTER");
            }
          } else {
            setCollaboratorEnabled(false);
          }
          if (fiduciary.blogPreferences && fiduciary.blogPreferences.apps) {
            setWidgets(fiduciary.blogPreferences.apps);
          }
        } else {
          setCollaboratorEnabled(false);
        }
      } else {
        setCollaboratorEnabled(false);
      }
    }
  }, [fiduciary, location]);

  const renderCommunityPortal = () => {
    return (
      <div id="page">
        <AppsList
          apps={apps}
          activeApp={activeApp}
          setActiveApp={setActiveApp}
        />
        <Drawer
          activeApp={activeApp}
          hasPremiumWatch={hasPremiumWatch}
          setActiveApp={setActiveApp}
        />

        {/* {(widgets.includes("ARTICLE") || widgets.includes("TAMTAMIT")) && (
          <ArticleWidget widgets={widgets} activeApp={activeApp} />
        )} */}

        <div className={styles.widgets}>
          {widgets.includes("BLOG_NL_CREATE") && <SendWidget />}
        </div>

        {widgets.includes("TALK_FLOAT") && handleTalkWidget(store)}
      </div>
    );
  };

  if (!fiduciary) {
    return null;
  }

  if (!collaboratorEnabled) {
    return (
      <div className={`${styles.emptyPage} grid-container`}>
        <h2 className={styles.emptyPage_title}>
          {_("portal_collaborator_title")}
        </h2>
        <p className={styles.emptyPage_help}>{_("portal_collaborator_txt")}</p>
        {loggedIsAdmin ? (
          <Button onClick={() => navigate("/settings")}>
            Configurer le portail collaborateur
          </Button>
        ) : null}
      </div>
    );
  }

  return renderCommunityPortal();
};

export default CollaboratorPortal;
