import {
  AUTH_COOKIE_NAME,
  COMMUNITY_COOKIE_NAME,
  APP_ENV,
  TTP_ONBOARDING_URL,
  TTP_HOME_URL,
  UA_DOMAIN_NAME,
} from "../config";

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie =
    name +
    " = " +
    encodeURIComponent(value) +
    "  " +
    (!expires ? "" : "; expires = " + expires.toUTCString()) +
    (!path ? "" : "; path = " + path) +
    (!domain ? "" : "; domain = " + domain) +
    (secure === true ? "; secure" : "");
}

export const setCookieMaxAge = (name, value, options = {}) => {
  options = {
    path: "/",
    // add other defaults here if necessary
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getAuthInfosFromCookie = () => {
  const cookie = getCookie(AUTH_COOKIE_NAME);

  if (!cookie) {
    return null;
  }

  return JSON.parse(cookie);
};

export const getCommunityFromCookie = () => {
  const cookie = getCookie(COMMUNITY_COOKIE_NAME);

  if (!cookie) {
    return null;
  }

  return JSON.parse(cookie);
};

export const authHasExpired = (authInfos) => {
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    !authInfos ||
    typeof authInfos !== "object" ||
    !authInfos.createdAt ||
    !authInfos.expiresIn ||
    now >= parseFloat(authInfos.createdAt) + parseFloat(authInfos.expiresIn)
  );
};

export const getLocalStorageUser = () => {
  const stringUser = localStorage.getItem("user");
  let user = [];

  try {
    user = JSON.parse(stringUser);
  } catch (e) {}
  return user instanceof Object ? user : {};
};

export const getLocalStorageAuth = () => {
  const stringAuth = localStorage.getItem("auth");
  let auth = {};

  try {
    auth = JSON.parse(stringAuth);
  } catch (e) {}
  return typeof auth === "object" ? auth : {};
};

export const setLocalStorageAuth = (auth) => {
  if (typeof auth === "object") {
    localStorage.setItem("auth", JSON.stringify(auth));
    return auth;
  }
  if (auth == null) {
    localStorage.removeItem("auth");
  }
};

export const getLocalStorageCookie = () => {
  const stringAuth = localStorage.getItem("cookie_auth");
  let cookie_auth = null;

  try {
    cookie_auth = JSON.parse(stringAuth);
  } catch (e) {}
  return cookie_auth && typeof cookie_auth === "object" ? cookie_auth : null;
};

export const setLocalStorageCookie = (cookie_auth) => {
  if (cookie_auth && typeof cookie_auth === "object") {
    localStorage.setItem("cookie_auth", JSON.stringify(cookie_auth));
    return cookie_auth;
  }
  if (cookie_auth == null) {
    localStorage.removeItem("auth");
  }
};

export const logout = (redirect = true) => {
  let dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

  setCookie(`ttp_auth_${APP_ENV}`, "", dtExpire, "/", "unitedassociates.be");
  setCookie(
    `ttp_community_${APP_ENV}`,
    "",
    dtExpire,
    "/",
    "unitedassociates.be"
  );

  if (redirect) {
    window.location.assign(`${TTP_ONBOARDING_URL}`);
  }
};

export const getUserCurrentNavCommunity = (user, currentNavCommunityId) => {
  if (
    currentNavCommunityId &&
    user.communities &&
    user.communities.length > 0
  ) {
    let currentUserNavCommunity = user.communities.filter(
      (c) => c.id === currentNavCommunityId
    )[0];
    if (currentUserNavCommunity) {
      return currentUserNavCommunity;
    }
  }
  return null;
};

export const createAuthCookie = (data) => {
  let dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
  const expiry = Date.now() + data.token.expires_in;

  setCookieMaxAge(
    `ttp_auth_${APP_ENV}`,
    JSON.stringify({
      token: data.token.access_token,
      id: data.user.id,
      email: data.user.mainEmail,
      expiresIn: data.token.expires_in,
      createdAt: data.token.createdAt,
      expiry: expiry,
      scope: data.token.scope,
      // fullName: encodeURIComponent(
      //   data.user.firstName + " " + data.user.lastName
      // ),
    }),
    {
      "max-age": data.token.expires_in,
      expires: dtExpire,
      path: "/",
      domain: UA_DOMAIN_NAME,
    }
  );
};

export const createCommunityCookie = (data) => {
  let dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() * 1000);
  // const expiry = Date.now() + data.token.expires_in;

  setCookieMaxAge(COMMUNITY_COOKIE_NAME, JSON.stringify(data), {
    // "max-age": data.token.expires_in,
    expires: dtExpire,
    path: "/",
    domain: UA_DOMAIN_NAME,
  });
};
