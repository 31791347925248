import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import InputMask from "react-input-mask";
import classNames from "classnames";
import { ClickAwayListener } from "@mui/base";

import Loader from "../../common/Loader";
import _ from "../../../i18n";
import {
  getContacts,
  deleteContact,
  getGroupByName,
  addContactToGroup,
  removeContactFromGroup,
  getGroups,
  checkContacts,
} from "../../../api";
import styles from "./Contact.module.scss";
import Pagination from "../../common/Pagination";
import { IconTrash } from "../../common/icons/IconTrash";
import IconRefresh from "../../common/icons/IconRefresh";
import IconClose from "../../common/icons/IconClose";
import IconPlus from "../../common/icons/IconPlus";

const ContactList = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const [sort, setSort] = useState({
    property: "lastName",
    dir: "asc",
  });
  const [dropDownId, setDropDownId] = useState(null);
  const [search, setSearch] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [groupSearchResults, setGroupSearchResults] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [saveGroup, setSaveGroup] = useState(false);
  const [savingGroup, setSavingGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ id: null, name: null });
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showExcludeList, setShowExcludeList] = useState(false);
  const [filterGroups, setFilterGroups] = useState([]);
  const [excludeGroups, setExcludeGroups] = useState([]);
  const [SFGroups, setSFGroups] = useState([]);
  const [SEGroups, setSEGroups] = useState([]);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [contactNl, setContactNl] = useState(false);
  const [pasteValue, setPasteValue] = useState("");
  const [showPasteBox, setShowPasteBox] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [foundContacts, setFoundContacts] = useState([]);
  const [foundResult, setFoundResult] = useState([]);
  const [notFoundContacts, setNotFoundContacts] = useState([]);
  const { token } = auth;
  const LIMIT = 10;

  let { isLoading, data } = useQuery(
    [
      "listContacts",
      token,
      sort,
      search,
      currentPage,
      filterGroups,
      excludeGroups,
      contactNl,
    ],
    async () => {
      if (token && fiduciary) {
        const response = await getContacts({
          token,
          search,
          page: currentPage,
          limit: LIMIT,
          sort,
          communityId: fiduciary.id,
          filterGroups: filterGroups.map((item) => item.id),
          excludeGroups: excludeGroups.map((item) => item.id),
          contactNl,
        });

        return response.data;
      }
    }
  );
  let { data: groupsData } = useQuery(
    ["listGroups", token, fiduciary],
    async () => {
      if (token && fiduciary) {
        const response = await getGroups({
          token,
          communityId: fiduciary.id,
        });
        setSFGroups(response.data.data);
        setSEGroups(response.data.data);
        return response.data.data;
      }
    }
  );

  useEffect(() => {
    if (props.refreshList) {
      queryClient.invalidateQueries("listContacts");
      props.setRefreshList(false);

      if (pasteValue && showPasteBox) {
        handlePasteData(pasteValue);
      }
    }
  }, [props.refreshList]);

  const toggleDropDown = (e, userId) => {
    e.stopPropagation();
    dropDownId === userId ? setDropDownId(null) : setDropDownId(userId);
  };

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearch(search);
    setCurrentPage(1);
  }, 1000);

  const handleDeleteAction = () => {
    if (currentDeleteItem) {
      setDeleting(true);
      deleteContact(auth.token, currentDeleteItem.contactClient.id)
        .then((resp) => {
          queryClient.invalidateQueries("listContacts");
          toast.success(_("successfully_deleted"));
          setDeleting(false);
          setIsOpenDeleteModal(false);
        })
        .catch((e) => {
          setDeleting(false);
        });
    }
  };

  const getActions = (item) => {
    if (
      auth.user.role === "OFFICIAL" ||
      auth.user.role === "EXTERNAL" ||
      ((auth.user.role === "MANAGER" ||
        auth.user.role == "FIDUCIARIES_ADMIN") &&
        item.role.type === "LEGAL_REPRESENTATIVE")
    ) {
      return null;
    }
    if (
      "LEGAL_REPRESENTATIVE" === auth.user.role &&
      "ADMIN" === auth.user.role.typeStatus &&
      "LEGAL_REPRESENTATIVE" === item.role.type &&
      auth.user.id !== item.id
    ) {
      return null;
    }

    const adminIsGroup = item.groups?.filter((i) => i.id === 535);
    if (adminIsGroup && adminIsGroup.length > 0) {
      return (
        <div className={styles.lockIcon}>
          <i className="icon-ttp-locked"></i>
        </div>
      );
    }

    return (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              props.setSideBar && props.setSideBar("CONTACT_FORM");
              props.setEmailValue && props.setEmailValue("");
              props.setCurrentItem(item);
              props.setIsEditingContact(true);
            }}
          >
            {_("edit")}
          </li>

          {(!["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role) ||
            "ADMIN" === auth.user.type) &&
            auth.user.id !== item.id && (
              <li
                onClick={() => {
                  setCurrentDeleteItem(item);
                  setIsOpenDeleteModal(true);
                }}
              >
                {_("delete")}
              </li>
            )}
        </ul>
      </div>
    );
  };

  const selectContact = (item) => {
    if (selectedIds.includes(item.id)) {
      setSelectedIds([...selectedIds.filter((element) => element !== item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
      if (item.groups) {
        const list = groups;
        item.groups.map((element) => {
          if (!list.map((l) => l.id).includes(element.id)) {
            list.push(element);
          }
        });
        setGroups([...list]);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const dataTab = showPasteBox ? foundResult : data.data;
      setSelectedIds(dataTab.map((item) => item.id));
      const list = groups;
      dataTab.map((item) => {
        item.groups.map((element) => {
          if (!list.map((l) => l.id).includes(element.id)) {
            list.push(element);
          }
        });
      });
      setGroups([...list]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedIds.length === 0) {
      setGroups([]);
    }
  }, [selectedIds]);

  const handleGroupSearch = async (name) => {
    setShowCreateGroup(false);
    if (name && name.length > 0) {
      setFetching(true);
      try {
        const res = await getGroupByName(token, name, fiduciary.id);
        if (res) {
          setGroupSearchResults(res.data.data);
        }
        setFetching(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          setGroupSearchResults([]);
          setFetching(false);
          setShowCreateGroup(true);
        }
      }
    } else {
      setGroupSearchResults([]);
      setFetching(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      setSelectedGroup({ ...selectedGroup, name: e.target.value });
      setSaveGroup(true);
    }
  };

  const handleSaveGroup = async () => {
    setSavingGroup(true);
    await addContactToGroup(
      token,
      {
        group: selectedGroup,
        contacts: selectedIds,
      },
      fiduciary.id
    );
    setSavingGroup(false);
    queryClient.invalidateQueries("listGroups");
    reset();
  };
  const reset = () => {
    setGroupSearchResults([]);
    setSelectedGroup({ id: null, name: null });
    setSaveGroup(false);
    setDeleteGroup(false);
    setSelectedIds([]);
    setSelectAll(false);
    setFetching(false);
    setSearchValue("");
    setShowCreateGroup(false);
    props.setRefreshList(true);
  };

  const handleDeleteGroup = async () => {
    setDeletingGroup(true);
    await removeContactFromGroup(token, {
      group: selectedGroup,
      contacts: selectedIds,
    });
    setDeletingGroup(false);
    reset();
  };

  const handleSearchPaste = async (e) => {
    e.preventDefault();

    const data = e.clipboardData.getData("Text");
    setPasteValue(data);
    handlePasteData(data);
  };

  const handlePasteData = async (data) => {
    let allEmails = [];
    if (data.includes(";")) {
      allEmails = data.split(";");
    } else if (data.includes(",")) {
      allEmails = data.split(",");
    } else if (data.includes("\t")) {
      allEmails = data.split("\t");
    } else {
      allEmails = data.split(/\r\n|\r|\n/);
    }

    if (allEmails.length == 0) {
      return null;
    }

    setShowPasteBox(true);
    setUploading(true);

    const d = new Date();
    let time = d.getTime();
    var file = new File([JSON.stringify(allEmails)], `data-${time}.txt`, {
      type: "text/plain",
    });

    try {
      const response = await checkContacts(token, file, fiduciary.id);
      setFoundContacts(response.data.data.found.map((i) => i.mainEmail));
      setFoundResult(response.data.data.found);
      setNotFoundContacts(response.data.data.notFound);
      setUploading(false);
    } catch (e) {
      setUploading(false);
    }
  };

  const handleAddContact = (email) => {
    props.setCurrentItem(null);
    props.setEmailValue(email);
    props.setSideBar("CONTACT_FORM");
  };

  const handleDeleteContact = (email) => {
    const tab = foundContacts.filter((i) => i !== email);
    setFoundContacts(tab);

    const t = foundResult.filter((i) => i.mainEmail !== email);
    setFoundResult(t);
  };

  const handleCopy = async (tab) => {
    let values = "";
    if (pasteValue.includes(";")) {
      values = tab.join(";");
    } else if (pasteValue.includes(",")) {
      values = tab.join(",");
    } else if (pasteValue.includes("\t")) {
      values = tab.join("\t");
    } else {
      values = tab.join("\n");
    }

    try {
      await navigator.clipboard?.writeText(values);
      toast.success("Données copiées");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const renderList = (data) => {
    return data.map((item) => (
      <tr key={item.id}>
        <td>{getActions(item)}</td>
        <td>
          <input
            type="checkbox"
            checked={selectedIds.includes(item.id)}
            onClick={() => selectContact(item)}
          />
        </td>
        <td>
          {`${item.lastName ? item.lastName : ""} ${
            item.firstName ? item.firstName : ""
          }`}
          {((item.lastName !== undefined && item.lastName !== "") ||
            (item.firstName !== undefined && item.firstName !== "")) && <br />}
          {item.mainEmail}
        </td>
        <td>
          {item.groups &&
            item.groups.map((element, i) => (
              <span
                className={styles.groupName}
                style={{
                  marginBottom: i === item.groups.length - 1 ? "0" : "",
                }}
              >
                {element.name}
              </span>
            ))}
        </td>
        <td className={styles.nowrap}>
          {item.mainPhone ? formatPhoneNumberIntl(item.mainPhone) : "-"}
        </td>
        <td>{item.gender ? _(item.gender.toLowerCase()) : "-"}</td>
        <td>
          {item.language
            ? item.language.charAt(0).toUpperCase() + item.language.slice(1)
            : "-"}
        </td>
        <td>
          {item?.contactClient?.details ? (
            <>
              {item.contactClient.details.name &&
                item.contactClient.details.name}
              <br />
              {item.contactClient.details.uen && (
                <div className={styles.uen}>
                  BE
                  <InputMask
                    readOnly={true}
                    mask="9999.999.999"
                    value={item.contactClient.details.uen}
                  />
                </div>
              )}
            </>
          ) : (
            "-"
          )}
        </td>
        <td>
          {item?.contactClient?.details && item.contactClient.details.fonction
            ? item.contactClient.details.fonction
            : "-"}
        </td>
      </tr>
    ));
  };

  return (
    <>
      <div className={styles.listContainer}>
        <h3>
          {_("contacts_list")}{" "}
          {showPasteBox && !uploading ? (
            <span>({foundContacts.length})</span>
          ) : (
            !isLoading && data?.nbResult && <span>({data.nbResult})</span>
          )}
        </h3>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder="Recherche ..."
            onChange={(e) => handleSearchChange(e)}
            onPaste={handleSearchPaste}
          />
        </div>
      </div>
      {props.page && props.page === "SETTING" && (
        <ul className={styles.tabForms}>
          <li
            onClick={() => {
              props.setCurrentItem(null);
              props.setEmailValue && props.setEmailValue("");
              props.setSideBar("CONTACT_FORM");
            }}
            className={props.sideBar === "CONTACT_FORM" ? styles.active : ""}
          >
            Ajout Manuel
          </li>
          <li
            onClick={() => {
              props.setCurrentItem(null);
              props.setSideBar("CONTACT_IMPORT");
            }}
            className={props.sideBar === "CONTACT_IMPORT" ? styles.active : ""}
          >
            Import (.xls)
          </li>
        </ul>
      )}

      {showPasteBox && (
        <div className={styles.pasteBox}>
          <span
            className={styles.pasteBox_close}
            onClick={() => {
              setShowPasteBox(false);
              setPasteValue("");
              setFoundResult([]);
            }}
          >
            <IconClose size="15" />
          </span>
          {uploading ? (
            <div className={styles.load}>
              <span className={styles.spinner}></span>
            </div>
          ) : (
            <div className={styles.paste_content}>
              <div className={styles.paste_left}>
                <label>
                  Trouvé ({foundContacts.length})
                  {foundContacts.length > 0 && (
                    <span
                      className={styles.paste_copy}
                      onClick={() => handleCopy(foundContacts)}
                    >
                      <i className="icon-ttp-copy"></i>
                    </span>
                  )}
                </label>

                <div className={styles.paste_items}>
                  {foundContacts.map((item, idx) => (
                    <span className={styles.paste_item} key={`f${idx}`}>
                      <span
                        className={styles.paste_add}
                        onClick={() => handleDeleteContact(item)}
                      >
                        <IconClose size="8" />
                      </span>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className={styles.paste_right}>
                <label>
                  Non trouvé ({notFoundContacts.length}){" "}
                  {notFoundContacts.length > 0 && (
                    <span
                      className={styles.paste_copy}
                      onClick={() => handleCopy(notFoundContacts)}
                    >
                      <i className="icon-ttp-copy"></i>
                    </span>
                  )}
                </label>

                <div className={styles.paste_items}>
                  {notFoundContacts.map((item, idx) => (
                    <span className={styles.paste_item} key={`nf${idx}`}>
                      <span
                        className={styles.paste_add}
                        onClick={() => handleAddContact(item)}
                      >
                        <IconPlus />
                      </span>
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.groupFilter}>
        <div className={styles.select}>
          <button
            className={showList ? styles.active_btn : ""}
            onClick={() => {
              setSFGroups(groupsData);
              setShowList(!showList);
            }}
          >
            {_("group_filter")}
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.77771 5.60814C5.37747 6.10332 4.62253 6.10332 4.22229 5.60814L1.12024 1.77036C0.591671 1.11642 1.05711 0.141741 1.89796 0.141741L8.10204 0.141741C8.94289 0.141742 9.40833 1.11642 8.87976 1.77036L5.77771 5.60814Z"
                fill="#6D7F92"
              />
            </svg>
          </button>
          <button
            className={styles.refresh}
            onClick={() => setFilterGroups([])}
          >
            <IconRefresh />
          </button>
          {showList && (
            <ClickAwayListener onClickAway={() => setShowList(false)}>
              <div className={styles.list}>
                <input
                  type="text"
                  placeholder={_("search_group")}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setSFGroups(
                        groupsData.filter((el) =>
                          el.name
                            .toLowerCase()
                            .startsWith(e.target.value.toLocaleLowerCase())
                        )
                      );
                    } else {
                      setSFGroups(groupsData);
                    }
                  }}
                />
                <ul>
                  {SFGroups &&
                    SFGroups.map((item) => (
                      <li
                        className={classNames(
                          filterGroups.includes(item) ? styles.selected : "",
                          excludeGroups.includes(item) ? styles.disabled : ""
                        )}
                        key={item.id}
                        onClick={() => {
                          if (!excludeGroups.includes(item)) {
                            if (filterGroups.includes(item)) {
                              setFilterGroups(
                                filterGroups.filter((el) => el.id !== item.id)
                              );
                            } else {
                              setFilterGroups([...filterGroups, item]);
                            }
                          }
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            </ClickAwayListener>
          )}
        </div>
        <div className={styles.select}>
          <button
            className={showExcludeList ? styles.active_btn : ""}
            onClick={() => {
              setSEGroups(groupsData);
              setShowExcludeList(!showExcludeList);
            }}
          >
            {_("group_exclude")}
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.77771 5.60814C5.37747 6.10332 4.62253 6.10332 4.22229 5.60814L1.12024 1.77036C0.591671 1.11642 1.05711 0.141741 1.89796 0.141741L8.10204 0.141741C8.94289 0.141742 9.40833 1.11642 8.87976 1.77036L5.77771 5.60814Z"
                fill="#6D7F92"
              />
            </svg>
          </button>
          <button
            className={styles.refresh}
            onClick={() => setExcludeGroups([])}
          >
            <IconRefresh />
          </button>
          {showExcludeList && (
            <ClickAwayListener onClickAway={() => setShowExcludeList(false)}>
              <div className={styles.list}>
                <input
                  type="text"
                  placeholder={_("search_group")}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setSEGroups(
                        groupsData.filter((el) =>
                          el.name
                            .toLowerCase()
                            .startsWith(e.target.value.toLocaleLowerCase())
                        )
                      );
                    } else {
                      setSEGroups(groupsData);
                    }
                  }}
                />
                <ul>
                  {SEGroups &&
                    SEGroups.map((item) => (
                      <li
                        className={classNames(
                          excludeGroups.includes(item) ? styles.selected : "",
                          filterGroups.includes(item) ? styles.disabled : ""
                        )}
                        key={item.id}
                        onClick={() => {
                          if (!filterGroups.includes(item)) {
                            if (excludeGroups.includes(item)) {
                              setExcludeGroups(
                                excludeGroups.filter((el) => el.id !== item.id)
                              );
                            } else {
                              setExcludeGroups([...excludeGroups, item]);
                            }
                          }
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            </ClickAwayListener>
          )}
        </div>
        <div className={styles.wrapCheckbox}>
          <input
            type="checkbox"
            checked={contactNl}
            onClick={() => {
              setCurrentPage(1);
              setContactNl(!contactNl);
            }}
          />
          <span>Contact NL</span>
        </div>
      </div>
      {filterGroups.length > 0 && (
        <div className={styles.groupList}>
          <span>Les groupes filtrés</span>
          <ul>
            {filterGroups.map((item) => (
              <li key={item.id} className={styles.groupName}>
                {item.name}
                <div
                  onClick={() =>
                    setFilterGroups(
                      filterGroups.filter((el) => el.id !== item.id)
                    )
                  }
                >
                  <IconClose size={10} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {excludeGroups.length > 0 && (
        <div className={styles.groupList}>
          <span>Les groupes exclus</span>
          <ul>
            {excludeGroups.map((item) => (
              <li key={item.id} className={styles.groupName}>
                {item.name}
                <div
                  onClick={() =>
                    setExcludeGroups(
                      excludeGroups.filter((el) => el.id !== item.id)
                    )
                  }
                >
                  <IconClose size={10} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {selectedIds.length > 0 && (
        <>
          {groups.length > 0 && (
            <div className={styles.groups}>
              {groups.map((item) => (
                <span className={styles.groupName}>
                  {item.name}
                  <div
                    onClick={() => {
                      setSelectedGroup({ id: item.id, name: item.name });
                      setDeleteGroup(true);
                    }}
                  >
                    <IconTrash size={14} />
                  </div>
                </span>
              ))}
            </div>
          )}
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Attribution de groupe à la sélection"
              onChange={(e) => {
                handleGroupSearch(e.target.value);
                setSearchValue(e.target.value);
              }}
              // onKeyDown={(e) => handleKeyPress(e)}
              value={searchValue}
            />
            {fetching ? (
              <ul className={styles.searchResults}>
                <li className={styles.center}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </li>
              </ul>
            ) : groupSearchResults.length > 0 ? (
              <ul className={styles.searchResults}>
                {groupSearchResults.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => {
                      setSelectedGroup({ id: item.id, name: item.name });
                      setSaveGroup(true);
                    }}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            ) : groupSearchResults.length === 0 && showCreateGroup ? (
              <ul className={styles.searchResults}>
                <li
                  onClick={() => {
                    setSelectedGroup({ ...selectedGroup, name: searchValue });
                    setSaveGroup(true);
                  }}
                >
                  {_("create_group")}
                </li>
              </ul>
            ) : null}
          </div>
        </>
      )}

      <div className={styles.tableContainer}>
        <table className={`${styles.table} unstriped`}>
          <thead>
            <tr>
              <th></th>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onClick={() => handleSelectAll()}
                />
              </th>
              <th width="30%" className={styles.nowrap}>
                <span>{_("lastname") + " / " + _("firstname")}</span>
                <span className={styles.sortBox}>
                  <i
                    className={`icon-ttp-arrow-down ${
                      sort.property === "lastName" &&
                      sort.dir === "asc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "lastName", dir: "asc" })
                    }
                  ></i>
                  <i
                    className={`icon-ttp-arrow-up ${
                      sort.property === "lastName" &&
                      sort.dir === "desc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "lastName", dir: "desc" })
                    }
                  ></i>
                </span>
              </th>
              <th width="40%">{_("groups")}</th>
              <th width="25%">{_("phone_number")}</th>
              <th width="25%">{_("gender")}</th>
              <th>{_("language")}</th>
              <th>Entreprise</th>
              <th>Fonction</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={9} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : showPasteBox ? (
              renderList(foundResult)
            ) : data?.data && data.data.length > 0 ? (
              renderList(data.data)
            ) : (
              <tr>
                <td colSpan={9} className={styles.centerCell}>
                  Liste vide (aucun contact n'a été ajouté)
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!showPasteBox && data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type="send"
        isOpen={saveGroup}
        onCancel={() => {
          setSelectedGroup({ id: null, name: null });
          setSaveGroup(false);
        }}
        onConfirm={() => handleSaveGroup()}
        inProcess={savingGroup}
        title={_("save_confirm")}
        text={_("text_save_group")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      <ModalConfirm
        type="delete"
        isOpen={deleteGroup}
        onCancel={() => {
          setSelectedGroup({ id: null, name: null });
          setDeleteGroup(false);
        }}
        onConfirm={() => handleDeleteGroup()}
        inProcess={deletingGroup}
        title={_("delete_confirm")}
        text={_("text_delete_group")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default ContactList;
