import React, { useEffect, useState } from "react";
import AppendHead from "react-append-head";

import { APP_ENV } from "../../config";
import ChatBotWidget from "./ChatBotWidget";

const ChatBot = ({ user, token, lng }) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  const scriptId = "chatbot-widget-script";

  useEffect(() => {
    // Check if the script is already loaded
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src =
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/chatbot/production/static/js/chatBotWidget.js";
      script.id = scriptId;
      script.async = true;
      script.onload = () => setIsWidgetLoaded(true);
      script.onerror = (e) =>
        console.error("Failed to load chatbot widget:", e);

      document.body.appendChild(script);
    } else {
      setIsWidgetLoaded(true);
    }

    return () => {
      setIsWidgetLoaded(false);
    };
  }, []);

  return (
    <>
      <AppendHead>
        <link
          name="ttpchatbot"
          href={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/chatbot/production/static/css/chatBotWidget.css`}
          rel="stylesheet"
        />
      </AppendHead>
      <div
        style={{ minHeight: "80vh", paddingLeft: "4rem", position: "relative" }}
      >
        {isWidgetLoaded && (
          <ChatBotWidget token={token} lng={lng} user={user} env={APP_ENV} />
        )}
      </div>
    </>
  );
};

export default ChatBot;
