import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactCodeInput from "react-code-input";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Stepper from "../common/Stepper";
import _ from "../../i18n";

import {
  getClientCredential,
  emailAcceptInvitation,
  emailRejectInvitation,
  getStudentInfos,
  profileCheckMail,
  profileValidateMail,
  profileCheckPhone,
  saveSharedPremium,
  profileValidatePhone,
} from "../../api";
import { setAuthToken } from "../../store";
import {
  validatePhone,
  formatPhone,
  validateEmail,
  cleanEmail,
} from "../../utils";

import LeftSide from "../Login/LeftSide";
import styles from "./Invitation.module.scss";

const Invitation = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [fiduciaryId, setfiduciaryId] = useState(null);
  const [fiduciaryName, setfiduciaryName] = useState("");
  const [fiduciaryMode, setFiduciaryMode] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [student, setStudent] = useState(null);
  const [step, setStep] = useState(1); // 4 steps
  const [redirectLink, setRedirentLink] = useState("/");
  const [showPersonalEmail, setShowPersonalEmail] = useState(false);
  const [personalEmail, setPersonalEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [errorEmailCode, setErrorEmailCode] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showPhoneCode, setShowPhoneCode] = useState(false);
  const [errorPhoneCode, setErrorPhoneCode] = useState("");

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    let fidId = null,
      userId = null,
      fidType = null;
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "id") {
        setId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "uid") {
        userId = decodeURIComponent(pair[1]);
        setUserId(userId);
      }
      if (pair[0] === "email") {
        setEmail(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "fid") {
        fidId = decodeURIComponent(pair[1]);
        setfiduciaryId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "name" && pair[1].length > 0) {
        setfiduciaryName(decodeURIComponent(pair[1].replace(/\+/g, " ")));
      }
      if (pair[0] === "role") {
        setRole(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "type") {
        fidType = decodeURIComponent(pair[1]);
        setFiduciaryMode(fidType);
      }
      if (pair[0] === "roles") {
        const jsonRoles = decodeURIComponent(pair[1]);
        if (jsonRoles) {
          setRoles(JSON.parse(jsonRoles));
        }
      }
    }

    if (!auth.token) {
      getClientCredential().then((resp) => {
        const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
        const token = helpResp.token.access_token;
        dispatch(setAuthToken(token));

        if (fidType === "UNIVERSITY" && userId && fidId) {
          setIsSaving(true);
          getStudentInfos(token, userId, fidId)
            .then((resp) => {
              setStudent(resp.data.data);

              if (resp.data.data.alreadyAccepted) {
                if (resp.data.data.hasValidPhone) {
                  navigate("/");
                } else {
                  setStep(3);
                }
              }
            })
            .finally(() => setIsSaving(false));
        }
      });
    }
  }, []);

  const handleAccept = () => {
    if (id || userId) {
      setIsSaving(true);
      emailAcceptInvitation(auth.token, userId, id, email, fiduciaryId)
        .then((resp) => {
          if (resp.data.data && resp.data.data.redirectLink) {
            navigate(resp.data.data.redirectLink);
          }
          // navigate("/");
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
          navigate("/");
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
      navigate("/");
    }
  };

  const handleReject = () => {
    if (id || userId) {
      setIsSaving(true);
      emailRejectInvitation(auth.token, userId, id, email, fiduciaryId)
        .then((resp) => {
          toast.success(_("invitation_reject"));
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
    }
  };

  const handleValidateStudent = async () => {
    emailAcceptInvitation(auth.token, userId, id, email, fiduciaryId)
      .then(async (resp) => {
        if (resp.data.data && resp.data.data.redirectLink) {
          setRedirentLink(resp.data.data.redirectLink);
        }

        try {
          await saveSharedPremium({
            token: auth.token,
            users: [userId],
            premiumSharedBy: 8650,
          });

          setStep(3);
          // if (resp.data.data && resp.data.data.redirectLink) {
          //   navigate(resp.data.data.redirectLink);
          // }
        } catch (e) {
          setStep(3);
          // if (resp.data.data && resp.data.data.redirectLink) {
          //   navigate(resp.data.data.redirectLink);
          // }
        }
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        ) {
          toast.error(_(e.response.data.errors[0].message));
        } else {
          toast.error(_("error"));
        }
        navigate("/");
      })
      .finally(() => setIsSaving(false));
  };
  const sendEmailCode = () => {
    setIsSaving(true);
    profileCheckMail({
      token: auth.token,
      userId,
      email: personalEmail,
      language: lng,
    })
      .then((resp) => {
        // setStep(2);
        setShowPersonalEmail(false);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.error
        ) {
          toast.error(_(e.response.data.errors.error));
        } else {
          toast.error(_("error"));
        }
      })
      .finally(() => setIsSaving(false));
  };

  const sendPhoneCode = () => {
    setIsSaving(true);
    profileCheckPhone({
      token: auth.token,
      userId,
      phone,
      language: lng,
    })
      .then((resp) => {
        setShowPhoneCode(true);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.error
        ) {
          toast.error(_(e.response.data.errors.error));
        } else {
          toast.error(_("error"));
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleStudentAccept = () => {
    if (id || userId) {
      let isValidEmail = false;
      if (student.hasValidEmail) {
        isValidEmail = true;
      } else {
        if (student.personalEmails.includes(email)) {
          isValidEmail = true;
        }
      }
      if (isValidEmail) {
        // setStep(3);
        setIsSaving(true);
        handleValidateStudent();
      } else {
        // sendEmailCode();
        setShowPersonalEmail(true);
        setStep(2);
      }
    } else {
      toast.error(_("error"));
      navigate("/");
    }
  };

  const handleEmailCode = () => {
    if (emailCode.length < 6) {
      setErrorEmailCode("Veuillez entrer le code de vérification");
      return null;
    }

    setErrorEmailCode("");
    setIsSaving(true);
    profileValidateMail({
      token: auth.token,
      userId,
      email: personalEmail,
      key: emailCode,
    })
      .then((resp) => {
        handleValidateStudent();
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.error
        ) {
          toast.error(_(e.response.data.errors.error));
        } else {
          toast.error(_("error"));
        }
        setIsSaving(false);
      });
  };

  const handlePhoneKeyUp = (fieldValue, returnValue = false) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      const result = validatePhone(fieldValue);
      if (!result.valid) {
        value = _("validate_phone");
      }
    }
    setPhoneError(value);
    if (returnValue) {
      return value;
    }
  };

  const handlePhoneBlur = (e) => {
    setPhone(formatPhone(phone));
  };

  const handlePhoneCode = () => {
    if (phoneCode.length < 6) {
      setErrorPhoneCode("Veuillez entrer le code de vérification");
      return null;
    }

    setErrorPhoneCode("");
    setIsSaving(true);
    profileValidatePhone({
      token: auth.token,
      userId,
      phone,
      key: phoneCode,
    })
      .then((resp) => {
        // setStep(3);
        navigate(redirectLink);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.error
        ) {
          toast.error(_(e.response.data.errors.error));
        } else {
          toast.error(_("error"));
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleBlurEmail = (e) => {
    const emailStr = cleanEmail(e.target.value);
    if (!validateEmail(emailStr)) {
      setEmailError(_("validate_email"));
    } else {
      setEmailError("");
    }
  };

  const handleValidateEmail = () => {
    const emailStr = cleanEmail(personalEmail);
    if (!validateEmail(emailStr)) {
      setEmailError(_("validate_email"));
      return null;
    }

    sendEmailCode();
  };

  const handleValidatePhone = () => {
    const error = handlePhoneKeyUp(phone, true);
    if (error.length > 0) {
      return null;
    }

    sendPhoneCode();
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <LeftSide />
        </div>
        <div className={styles.rightSide}>
          <div
            className={`${styles.content} ${
              fiduciaryMode === "UNIVERSITY" && styles.content_lg
            }`}
          >
            <h1 className={styles.title}>Invitation</h1>

            {fiduciaryMode === "UNIVERSITY" ? (
              <>
                <Stepper
                  steps={[
                    "Invitation",
                    "Validation de l'email",
                    "Validation du téléphone",
                    "Connexion",
                  ]}
                  currentStep={step}
                />
                {step === 1 ? (
                  <>
                    <p style={{ marginTop: "2rem" }}>
                      L'université <b>{fiduciaryName}</b> vous invite à
                      rejoindre United Associates{" "}
                      {role && (
                        <>
                          en tant{" "}
                          <b>{role === "OFFICIAL" ? "qu'étudiant" : _(role)}</b>
                        </>
                      )}
                    </p>

                    <div className={styles.actions}>
                      <Button onClick={() => handleReject()} variant="danger">
                        Refuser
                      </Button>
                      <Button onClick={() => handleStudentAccept()}>
                        Accepter
                      </Button>
                    </div>
                  </>
                ) : step === 2 ? (
                  <>
                    {showPersonalEmail ? (
                      <>
                        <div style={{ width: "60%" }}>
                          <label className={styles.labelTitle}>
                            Veuillez entrer votre email personnel:
                          </label>
                          <FormInput
                            name="email"
                            value={personalEmail}
                            label={_("email")}
                            error={emailError}
                            className="ttp-input-lg"
                            labelClassName="ttp-label-lg"
                            // autoComplete="off"
                            onChange={(e) => setPersonalEmail(e.target.value)}
                            onBlur={(e) => handleBlurEmail(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleValidateEmail();
                              }
                            }}
                          />
                        </div>
                        <div className={styles.actions}>
                          <Button onClick={() => handleValidateEmail()}>
                            Valider
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className={styles.labelTitle}>
                          {_("check_your_email")}
                        </label>
                        <label className="ttp-label ttp-label-lg">
                          Votre code
                        </label>
                        <div className={styles.codeBox}>
                          <ReactCodeInput
                            type="number"
                            fields={6}
                            value={emailCode}
                            onChange={(value) => setEmailCode(value)}
                            autoFocus={false}
                            className={styles.codeInput}
                            inputStyle={{
                              marginRight: "0.463rem",
                              width: "34px",
                              borderRadius: "4px",
                              fontSize: "14px",
                              height: "44px",
                              backgroundColor: "#F8F9FA",
                              border: "1px solid #B6BFC8",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        {errorEmailCode && (
                          <span className={styles.codeError}>
                            {errorEmailCode}
                          </span>
                        )}

                        <div className={styles.actions}>
                          <Button onClick={() => handleEmailCode()}>
                            Valider
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ width: "60%" }}>
                      <label className={styles.labelTitle}>
                        Veuillez entrer votre numéro de téléphone personnel:
                      </label>
                      <FormInput
                        name="phone"
                        required={true}
                        label={_("mobile_phone_number")}
                        placeholder="+32XXXXXXXX"
                        autoComplete="off"
                        value={phone}
                        error={phoneError}
                        className="ttp-input-lg"
                        labelClassName="ttp-label-lg"
                        onKeyUp={(e) => handlePhoneKeyUp(e.target.value)}
                        onChange={(e) => setPhone(e.target.value)}
                        handleBlur={(e) => handlePhoneBlur(e)}
                      />
                      {!showPhoneCode ? (
                        <div className={styles.actions}>
                          <Button onClick={() => handleValidatePhone()}>
                            Continuer
                          </Button>
                        </div>
                      ) : (
                        <>
                          <label className={styles.labelTitle}>
                            {_("check_your_phone")}
                          </label>
                          <label className="ttp-label ttp-label-lg">
                            Votre code
                          </label>
                          <div className={styles.codeBox}>
                            <ReactCodeInput
                              type="number"
                              fields={6}
                              value={phoneCode}
                              onChange={(value) => setPhoneCode(value)}
                              autoFocus={false}
                              className={styles.codeInput}
                              inputStyle={{
                                marginRight: "0.463rem",
                                width: "34px",
                                borderRadius: "4px",
                                fontSize: "14px",
                                height: "44px",
                                backgroundColor: "#F8F9FA",
                                border: "1px solid #B6BFC8",
                                textAlign: "center",
                              }}
                            />
                          </div>
                          {errorPhoneCode && (
                            <span className={styles.codeError}>
                              {errorPhoneCode}
                            </span>
                          )}

                          <div className={styles.actions}>
                            <Button onClick={() => handlePhoneCode()}>
                              Valider
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <p>
                  Vous êtes invité à rejoindre l'équipe <b>{fiduciaryName}</b>{" "}
                  sur United Associates{" "}
                  {role && (
                    <>
                      en tant que <b>{_(role)}</b>
                    </>
                  )}
                </p>

                {roles.length > 0 && (
                  <div>
                    <p className={styles.list_title}>
                      Vous avez déjà les roles suivants:
                    </p>
                    <ul className={styles.list}>
                      {roles.map((role, idx) => (
                        <li key={idx}>
                          {decodeURI(role.fid).replace(/\+/g, " ")}:{" "}
                          {role.type === "LEGAL_REPRESENTATIVE"
                            ? _(role.type + "_" + role.typeStatus)
                            : _(role.type)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <div className={styles.actions}>
                  <Button onClick={() => handleReject()} variant="danger">
                    Refuser
                  </Button>
                  <Button onClick={() => handleAccept()}>Accepter</Button>
                </div>
              </>
            )}

            {isSaving && <div className="lmask"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
