import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileWidget from "./ProfileWidget";
import { APP_ENV } from "../../config";
import { logout } from "../../utils";

const Profile = ({ contact, scrollToTop }) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const auth = useSelector(state => state.auth);
  const { token, user, scope } = auth;
  const language = useSelector(state => state.params.language);

  useEffect(
    () => {
      scope.includes("_ls") && logout();
      const script = document.createElement("script");
      let userSelected = contact ?? user;

      if (userSelected) {
        script.src = `https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
          APP_ENV === "rc2"
            ? "staging"
            : APP_ENV === "v2"
              ? "production"
              : APP_ENV
        }/static/js/profileWidget.js`;
        script.async = true;
        script.onload = () => {
          setIsWidgetLoaded(true);
        };

        document.body.appendChild(script);
      }

      return () => {
        // Cleanup if necessary
        if (userSelected) {
          document.body.removeChild(script);
        }
      };
    },
    [user, contact]
  ); // Empty dependency array to ensure the effect runs only once on component mount

  if (isWidgetLoaded) {
    let userSelected = contact ?? user;

    return (
      <>
        <link
          href={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
            APP_ENV === "rc2"
              ? "staging"
              : APP_ENV === "v2"
                ? "production"
                : APP_ENV
          }/static/css/profileWidget.css`}
          rel="stylesheet"
        />
        <ProfileWidget
          token={token}
          lng={language}
          userId={userSelected.id}
          isContact={!!contact}
          onScrollToTop={scrollToTop}
        />
      </>
    );
  }
  return null;
};

export default Profile;
