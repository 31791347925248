import React, { useEffect } from "react";

const ChatBotWidget = (props) => {
  useEffect(() => {
    if (
      window.chatBotWidget !== undefined &&
      document &&
      document.getElementById("ttp-chat-bot")
    ) {
      window.chatBotWidget.mount(
        props,
        document.getElementById("ttp-chat-bot")
      );
    }

    return () => {
      if (
        window.chatBotWidget !== undefined &&
        document &&
        document.getElementById("ttp-chat-bot")
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.chatBotWidget.unmount(document.getElementById("ttp-chat-bot"));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="ttp-chat-bot"></div>;
};

export default ChatBotWidget;
